import './NewsDetail.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import React, { FunctionComponent } from 'react'
import { HeadProps } from 'gatsby'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import PuxStructuredData from 'Shared/components/PuxStructuredData/PuxStructuredData'
import PuxVideo from 'Shared/components/PuxVideo/PuxVideo'
import getReadingDuration from 'Shared/utils/getReadingDuration'
import { msgid, ngettext, t } from 'ttag'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwareNewsPage } from 'Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxRepeater from 'Root/Shared/components/PuxRepeater/PuxRepeater'
import { RepeaterContentType } from 'Root/Shared/queries/repeater-queries'
import PuxLink from 'Root/Shared/utils/PuxLink'
import { getLocalizedUrl } from 'Root/Shared/utils/localeURL'

function getNewsJson(item: IEasySoftwareNewsPage) {
  const json = {
    '@context': `https://schema.org`,
    '@type': `NewsArticle`,
    headline: item.displayText,
    image: [
      item.newsImage?.mediaSelectorImage.resizePaths[0] ?? ``,
      item.newsImage?.mediaSelectorImage.resizePathsTablet[0] ?? ``,
      item.newsImage?.mediaSelectorImage.resizePathsMobile[0] ?? ``,
    ],
    datePublished: item.createdUtc,
    dateModified: item.modifiedUtc ?? ``,
  }

  return json
}

const NewsDetail: FunctionComponent<GenericPageProps<IEasySoftwareNewsPage>> = (props) => {
  const pageData = props.pageContext.pageData
  const categories = props.pageContext.newsCategories
  const tags = props.pageContext.newsTags
  const author = props.pageContext.newsAuthor
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const date = new Date(pageData.createdUtc)
  const minutes = getReadingDuration([
    pageData.newsPerex.html,
    pageData.newsContent.html,
  ])

  const widgetData = props.pageContext.widgets?.widgetZone
  const breadcrumbsData = props.pageContext.breadcrumbs
  const editorPath = `widgetZone,widgets`;

  console.log(props.pageContext.newsData)
  const filterOptions = {
    simpleFilterTermIDs: null,
    simpleFilterExplicitIDs: null,
    simpleFilterContentType: 'EasySoftwareNews' as RepeaterContentType,
    simpleFilterSortByIDs: false,
    puxFilterIsHidden: true,
  }

  return (
    <Layout localizedPath={pageData.localization?.localizations} footerData={footerData} headerData={headerData} pageContentItemId={pageData.contentItemId}>
      <PuxStructuredData structuredData={getNewsJson(pageData)} />
      <div className='NewsDetail'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <div className='Container'>
          <PuxContent
            contentModifier='newsDetail'
            sideCards={props.pageContext.sideCardWidgets}
          >
            <h1 className='NewsDetail-title'>
              <EasySoftwareEditorContainer
                type="input"
                content={pageData.displayText}
                wysiwygId={pageData.contentItemId + `-heading`}
                editorPath={`displayText`}
                pageContentItemId={pageData.contentItemId}
              />
            </h1>


            <div className='NewsDetail-meta'>
              {categories?.length && categories.length > 0 ? (
                <div className='NewsDetail-category NewsDetail-parameter'>
                  {categories.map(category => {
                    return (
                      <PuxLink
                        className={`NewsCategory-item`}
                        to={`${getLocalizedUrl(category.url)}`}
                      >
                        {category.icon ? (
                          <div className='NewsCategory-itemIcon'>
                            <svg width="20" height="20">
                              <mask id={category.title.replace(/[^a-z0-9]/gi, '-').toLowerCase()}>
                                <image xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={category.icon}></image>
                              </mask>
                              <rect x="0" y="0" width="20" height="20" mask={`url(#${category.title.replace(/[^a-z0-9]/gi, '-').toLowerCase()})`} />
                            </svg>
                          </div>
                        ) : null}
                        {category.title}
                      </PuxLink>
                    )
                  })}
                </div>
              ) : null}

              <div className='NewsDetail-date NewsDetail-parameter'>
                {date.toLocaleDateString()}
              </div>

              <div className='NewsDetail-time NewsDetail-parameter'>
                {ngettext(
                  msgid`${minutes} minute`,
                  `${minutes} minutes`,
                  minutes
                )}
              </div>

              {!author && pageData.newsAuthor && (
                <div className='NewsDetail-author NewsDetail-parameter'>
                  {pageData.newsAuthor}
                </div>
              )}

              {author && (
                <div className='NewsDetail-puxAuthor NewsDetail-parameter'>
                  <PuxMediaSelector {...author.image} disableLazyload />
                  <PuxLink
                    to={`${getLocalizedUrl(author.url)}`}
                  >
                    {author.name}
                  </PuxLink>
                </div>
              )}
            </div>

            <div className='NewsDetail-perex perex'>
              <EasySoftwareEditorContainer wysiwygId={pageData.contentItemId + '-perex'} pageContentItemId={pageData.contentItemId} content={pageData.newsPerex} editorPath={`newsPerex,html`} />
            </div>

            <div className='NewsDetail-image'>
              {pageData.puxVideo?.videoYTID.html && (
                <PuxVideo {...pageData.puxVideo} />
              )}
              {!pageData.puxVideo?.videoYTID.html && (
                <PuxMediaSelector {...pageData.newsImage} disableLazyload />
              )}
            </div>

            <div className='NewsDetail-content'>
              <EasySoftwareEditorContainer wysiwygId={pageData.contentItemId + '-content'} pageContentItemId={pageData.contentItemId} content={pageData.newsContent} editorPath={`newsContent,html`} />
            </div>

            <WidgetBuilder widgetBuilderData={widgetData} pageContentItemId={pageData.contentItemId} editorPath={editorPath} />

            {tags?.length && tags.length > 0 ? (
              <div className='NewsDetail-tags'>
                {tags.map(tags => {
                  return (
                    <PuxLink
                      className={`NewsDetail-tagsItem`}
                      to={`${getLocalizedUrl(tags.url)}`}
                    >
                      {tags.title}
                    </PuxLink>
                  )
                })}
              </div>
            ) : null}
          </PuxContent>
        </div>
      </div>
      {props.pageContext.newsData?.length && props.pageContext.newsData?.length > 0 ? (
        <div className='NewsRelated'>
          <div className='Container'>
            <h2>{t`EasyRedmine.News.RelatedItems.Title`}</h2>
            <PuxRepeater repeaterData={props.pageContext.newsData} puxSimpleFilter={filterOptions} simpleRepeaterPageSize={3} simpleRepeaterPagerOptions='showall' simpleRepeaterOnePageOnly simpleRepeaterFallbackCulture={{ html: '' }} />
          </div>
        </div>
      ) : null}
    </Layout>
  )
}

export default NewsDetail

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareNewsPage>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  if (metaTags?.metaTagsOGImage?.resizePaths?.length) {
    metaTags.metaTagsOGImage = pageData.newsImage?.mediaSelectorImage
  }

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}