import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/jetbrains-mono/400.css'
import './src/sass/style.scss'
import 'Shared/sass/style.scss'

import { isEditByUrl, isPreviewByUrl } from 'Shared/utils/edit/editURL'
import {
  MauticOnRouteChange,
  MauticOnWebLoad,
} from 'Shared/mautic/EasyRedmine/ERMauticScripts'
import { initDynamicRS } from 'Shared/utils/dynamicRS'
import {
  addEditorCookie,
  resetEditorCookie,
} from 'Shared/utils/edit/useEditorStatus'
import {
  addPreviewCookie,
  resetPreviewCookie,
} from 'Shared/utils/edit/usePreviewStatus'
import { getFromConfig } from 'Shared/utils/localeURL'
import {
  checkHash,
  isBrowser,
  scroller,
} from 'Shared/utils/PuxAnchorLink/puxAnchorUtils'
import { removeAllParamsWhenUTM } from 'Shared/utils/removeUTM'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = () => {
  const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
  const PROJECT = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`

  if (document.querySelector(`.NotFound`)) {
    removeAllParamsWhenUTM(window.location.href)
  }
  if (!window?.dataLayer) {
    window.dataLayer = []
  }
  fetch(process.env.GATSBY_CMS_URL + "/" + PROJECT.replace("GatsbyAI", "").replace("Gatsby", "") + "/puxapi/geolocation").then(response => response.text()).then(data => {
    window?.dataLayer?.push({
      event: 'geolocation_init',
      country: data
    })
  }).catch(error => error)
  initDynamicRS()
  resetEditorCookie()
  resetPreviewCookie()

  // if (process.env.GATSBY_ACTIVE_ENV === 'stage' || process.env.GATSBY_ACTIVE_ENV === 'production') {
  //   document.addEventListener('DOMContentLoaded', () => {
  //     /** init gtm after 3500 seconds - this could be adjusted */
  //     setTimeout(initGTM, 3500);
  //   });

  //   document.addEventListener('scroll', initGTMOnEvent);
  //   document.addEventListener('mousemove', initGTMOnEvent);
  //   document.addEventListener('touchstart', initGTMOnEvent);

  //   function initGTMOnEvent(event) {
  //     initGTM();
  //     event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
  //   }

  //   function initGTM() {
  //     let dataLayer = []
  //     if (window.gtmDidInit) {
  //       return false;
  //     }
  //     window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.onload = () => {
  //       if (dataLayer) {
  //         dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
  //       }
  //     }; // this part ensures PageViews is always tracked
  //     script.src = 'https://www.googletagmanager.com/gtm.js?id=' + process.env.GATSBY_GTMID;
  //     document.head.appendChild(script);
  //   }
  // }

  const culture = getFromConfig(LOCALE, `isoCode`, PROJECT)

  const script = document.createElement(`script`)
  script.type = `text/javascript`
  script.src = `https://cloud.google.com/ai/gen-app-builder/client?hl=${culture}`
  document.head.appendChild(script)

  if (culture === "en-GB") {
    const scriptHubspot = document.createElement(`script`)
    scriptHubspot.type = `text/javascript`
    scriptHubspot.src = `//js-eu1.hsforms.net/forms/embed/v2.js`
    scriptHubspot.classList.add("hbsptForm")
    scriptHubspot.onload = () => {
      if (window?.hbspt) {
        hbspt?.forms?.create({
          region: "eu1",
          portalId: "144157351",
          formId: "520a51eb-4491-47e0-8367-884040786f8e",
          onFormReady: () => {
          }
        })

        window.addEventListener('message', (event) => {
          if (
            event.data.type === 'hsFormCallback' &&
            event.data.eventName === 'onFormReady'
          ) {
            document.querySelector(".hbspt-form")?.classList.add("easyHiddenTrialForm")
          }
        });
      }
    }
    document.head.appendChild(scriptHubspot)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onInitialClientRender = () => {
  MauticOnWebLoad()
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onRouteUpdate = ({ location }) => {
  MauticOnRouteChange()

  if (isEditByUrl(location.pathname)) {
    addEditorCookie()
  } else {
    resetEditorCookie()
  }

  if (isPreviewByUrl(location.pathname)) {
    addPreviewCookie()
  } else {
    resetPreviewCookie()
  }

  function iOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  }

  if (iOS()) {
    document.querySelector(`#___gatsby`).classList.add(`is-iOS`)
  }

  let windowHash
  const offset = 0
  const duration = 800

  if (isBrowser) {
    window.gatsby_scroll_offset = offset
    window.gatsby_scroll_duration = duration
    windowHash = window.gatsby_scroll_hash
  }

  windowHash ? scroller(windowHash, offset) : checkHash(location, offset)

  if (isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined
  }
}
